<template>
  <div id="addscene">
    <div class="left">
      <div class="title">
        <span>+</span>
        <b style="cursor: pointer" @click="addCourses = true">添加授课过程</b>
      </div>
      <ul class="classList">
        <li v-for="(item, index) in list" :key="index">
          <div class="head">
            <img
              class="line"
              src="../../img/板块图标 - 副本_slices/矢量智能对象(3).png"
              alt=""
            />
            <span>{{ item.catalog_name }}</span>
            <el-dropdown
              trigger="click"
              placement="bottom-start"
              style="height: 50/96in; display: flex"
            >
              <span
                class="el-dropdown-link"
                style="display: flex; align-items: center"
                ><img
                  class="more"
                  src="../../img/板块图标 - 副本_slices/更  多 (1) 拷贝 6.png"
                  style="width: 0.2rem"
                  alt=""
              /></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span
                    @click="
                      bigEdito = true;
                      getEdit(item.id, item.catalog_name);
                    "
                    >编辑课程</span
                  >
                  <img
                    src="../../img/板块图标 - 副本_slices/编辑.png"
                    alt=""
                    style="width: 0.1rem; margin-left: 0.1rem"
                /></el-dropdown-item>
                <el-dropdown-item>
                  <span
                    @click="
                      smallCatalog = true;
                      getid(item.id);
                    "
                    >添加课程</span
                  >
                  <img
                    src="../../img/板块图标 - 副本_slices/复制.png"
                    alt=""
                    style="width: 0.1rem; margin-left: 0.1rem"
                /></el-dropdown-item>
                <el-dropdown-item>
                  <span @click="delBig(item.id)">删除课程</span>
                  <img
                    src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                    alt=""
                    style="width: 0.1rem; margin-left: 0.1rem"
                /></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <ul
            class="smallList"
            v-for="(item1, index1) in item.catalog"
            :key="index1"
          >
            <li
              @click="
                num = index + String(index1);
                getcatalogFile(item1.id);
              "
              :class="{ active: num == index + String(index1) }"
            >
              <img
                class="left_img"
                src="../../img/板块图标 - 副本_slices/矢量智能对象.png"
                alt=""
              />
              <span>{{ item1.catalog_name }}</span>
              <div class="right_img">
                <img
                  @click="
                    leftbj(item1.id, item1.catalog_name);
                    smallEdito = true;
                  "
                  src="../../img/板块图标 - 副本_slices/矢量智能对象(1).png"
                  alt=""
                />
                <img
                  @click="leftDel(item1.id)"
                  src="../../img/板块图标 - 副本_slices/矢量智能对象(2).png"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="right">
      <main>
        <!-- <router-view></router-view> -->
        <ul class="top">
          <li @click="addSome(1)" @mouseover="over(1)" @mouseleave="leave(1)">
            <img src="../../img/板块图标 - 副本_slices/5-5.png" alt="" />
            <img src="../../img/板块图标 - 副本_slices/5.png" alt="" />
            <b>插入PPT</b>
          </li>
          <li @click="addSome(2)" @mouseover="over(2)" @mouseleave="leave(2)">
            <img src="../../img/板块图标 - 副本_slices/7-7.png" alt="" />
            <img src="../../img/板块图标 - 副本_slices/7.png" alt="" />
            <b>插入文档</b>
          </li>
          <li @click="addSome(3)" @mouseover="over(3)" @mouseleave="leave(3)">
            <img src="../../img/板块图标 - 副本_slices/3-3.png" alt="" />
            <img src="../../img/板块图标 - 副本_slices/3.png" alt="" />
            <b>插入视频</b>
          </li>
          <li @click="addSome(4)" @mouseover="over(4)" @mouseleave="leave(4)">
            <img src="../../img/板块图标 - 副本_slices/6-6.png" alt="" />
            <img src="../../img/板块图标 - 副本_slices/6.png" alt="" />
            <b>插入音频</b>
          </li>
          <li @click="addSome(5)" @mouseover="over(5)" @mouseleave="leave(5)">
            <img src="../../img/板块图标 - 副本_slices/2-2.png" alt="" />
            <img src="../../img/板块图标 - 副本_slices/2.png" alt="" />
            <b>插入图文</b>
          </li>
          <li @click="addSome(6)" @mouseover="over(6)" @mouseleave="leave(6)">
            <img src="../../img/板块图标 - 副本_slices/1-1.png" alt="" />
            <img src="../../img/板块图标 - 副本_slices/1.png" alt="" />
            <b>插入问卷</b>
          </li>
          <li @click="addSome(7)" @mouseover="over(7)" @mouseleave="leave(7)">
            <img src="../../img/板块图标 - 副本_slices/9-9.png" alt="" />
            <img src="../../img/板块图标 - 副本_slices/9.png" alt="" />
            <b>插入模型</b>
          </li>
          <li @click="addSome(8)" @mouseover="over(8)" @mouseleave="leave(8)">
            <img src="../../img/板块图标 - 副本_slices/7-7.png" alt="" />
            <img src="../../img/板块图标 - 副本_slices/7.png" alt="" />
            <b>插入试题</b>
          </li>
          <li @click="addSome(9)" @mouseover="over(9)" @mouseleave="leave(9)">
            <img src="../../img/板块图标 - 副本_slices/4-4.png" alt="" />
            <img src="../../img/板块图标 - 副本_slices/4.png" alt="" />
            <b>插入实景</b>
          </li>
        </ul>
        <div class="main">
          <div class="title">添加场景资源</div>
          <div class="mains">
            <div
              class="box"
              v-show="
                pptlist.length == 0 &&
                wenlist.length == 0 &&
                videolist.length == 0 &&
                mp3list.length == 0 &&
                picturelist.length == 0 &&
                questionlist.length == 0 &&
                modellist.length == 0 &&
                topiclist.length == 0 &&
                reallist.length == 0
              "
            >
              <img
                src="../../img/板块图标 - 副本_slices/04_无数据.png"
                alt=""
                style="width: 413px"
              />
              <span>您还未添加任何授课资源~</span>
            </div>

            <!-- 01 ppt -->
            <ul class="pptList">
              <li v-for="(i, v) in pptlist" :key="v">
                <div class="elp" v-if="i.status">
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    style="height: 50/96in; display: flex"
                  >
                    <span
                      class="el-dropdown-link"
                      style="display: flex; align-items: right"
                      ><img
                        class="more"
                        src="../../img/上课/设 置.png"
                        style="width: 0.14rem"
                        alt=""
                    /></span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span @click="edit(i)">编辑</span>
                        <img
                          src="../../img/板块图标 - 副本_slices/编辑.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span @click="delBtn(i.id)">删除</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/复制.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span
                          @click="
                            seeFn(i.id);
                            dia1 = true;
                          "
                          >预览</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="img">
                  <img
                    src="../../img/板块图标 - 副本_slices/ppt 拷贝.png"
                    alt=""
                  />
                </div>
                <b>PPT资源展示</b>
                <div class="btn" v-show="i.status != 1">
                  <router-link
                    :to="{
                      path: '/main/addscene/pptresource',
                      query: {
                        csid: csid,
                        dataid: i.id,
                        filetype: i.file_type,
                      },
                    }"
                    >添加资源</router-link
                  >
                  <div class="del" @click="delBtn(i.id)">删除</div>
                </div>
                <div class="btn" v-show="i.status == 1">{{ i.file_name }}</div>
              </li>

              <!-- 02 文档 -->
              <li v-for="(i, v) in wenlist" :key="v + '1'">
                <div class="elp" v-if="i.status">
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    style="height: 50/96in; display: flex"
                  >
                    <span
                      class="el-dropdown-link"
                      style="display: flex; align-items: right"
                      ><img
                        class="more"
                        src="../../img/上课/设 置.png"
                        style="width: 0.14rem"
                        alt=""
                    /></span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span @click="edit(i)">编辑</span>
                        <img
                          src="../../img/板块图标 - 副本_slices/编辑.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span @click="delBtn(i.id)">删除</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/复制.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span
                          @click="
                            seeFn2(i.id);
                            dia2 = true;
                          "
                          >预览</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="img">
                  <img
                    src="../../img/板块图标 - 副本_slices/word (3) 拷贝 2.png"
                    alt=""
                  />
                </div>
                <b>文档资源展示</b>
                <div class="btn" v-show="i.status != 1">
                  <router-link
                    :to="{
                      path: '/main/addscene/pptresource',
                      query: {
                        csid: csid,
                        dataid: i.id,
                        filetype: i.file_type,
                      },
                    }"
                    >添加资源</router-link
                  >
                  <div class="del" @click="delBtn(i.id)">删除</div>
                </div>
                <div class="btn" v-show="i.status == 1">{{ i.file_name }}</div>
              </li>

              <!-- 03 视频 -->
              <li v-for="(i, v) in videolist" :key="v + '2'">
                <div class="elp" v-if="i.status">
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    style="height: 50/96in; display: flex"
                  >
                    <span
                      class="el-dropdown-link"
                      style="display: flex; align-items: right"
                      ><img
                        class="more"
                        src="../../img/上课/设 置.png"
                        style="width: 0.14rem"
                        alt=""
                    /></span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span @click="edit(i)">编辑</span>
                        <img
                          src="../../img/板块图标 - 副本_slices/编辑.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span @click="delBtn(i.id)">删除</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/复制.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span
                          @click="
                            seeFn3(i.id);
                            dia3 = true;
                          "
                          >预览</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="img">
                  <img
                    src="../../img/板块图标 - 副本_slices/视频 (2) 拷贝 2.png"
                    alt=""
                  />
                </div>
                <b>视频资源展示</b>
                <div class="btn" v-show="i.status != 1">
                  <router-link
                    :to="{
                      path: '/main/addscene/pptresource',
                      query: {
                        csid: csid,
                        dataid: i.id,
                        filetype: i.file_type,
                      },
                    }"
                    >添加资源</router-link
                  >
                  <div class="del" @click="delBtn(i.id)">删除</div>
                </div>
                <div class="btn" v-show="i.status == 1">{{ i.file_name }}</div>
              </li>

              <!-- 04 音频 -->
              <li v-for="(i, v) in mp3list" :key="v + '3'">
                <div class="elp" v-if="i.status">
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    style="height: 50/96in; display: flex"
                  >
                    <span
                      class="el-dropdown-link"
                      style="display: flex; align-items: right"
                      ><img
                        class="more"
                        src="../../img/上课/设 置.png"
                        style="width: 0.14rem"
                        alt=""
                    /></span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span @click="edit(i)">编辑</span>
                        <img
                          src="../../img/板块图标 - 副本_slices/编辑.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span @click="delBtn(i.id)">删除</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/复制.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span
                          @click="
                            seeFn4(i.id);
                            dia4 = true;
                          "
                          >预览</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="img">
                  <img
                    src="../../img/板块图标 - 副本_slices/音频 (1) 拷贝 2.png"
                    alt=""
                  />
                </div>
                <b>音频资源展示</b>
                <div class="btn" v-show="i.status != 1">
                  <router-link
                    :to="{
                      path: '/main/addscene/pptresource',
                      query: {
                        csid: csid,
                        dataid: i.id,
                        filetype: i.file_type,
                      },
                    }"
                    >添加资源</router-link
                  >
                  <div class="del" @click="delBtn(i.id)">删除</div>
                </div>
                <div class="btn" v-show="i.status == 1">{{ i.file_name }}</div>
              </li>

              <!-- 05 图文 -->
              <li v-for="(i, v) in picturelist" :key="v + '4'">
                <div class="elp" v-if="i.status">
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    style="height: 50/96in; display: flex"
                  >
                    <span
                      class="el-dropdown-link"
                      style="display: flex; align-items: right"
                      ><img
                        class="more"
                        src="../../img/上课/设 置.png"
                        style="width: 0.14rem"
                        alt=""
                    /></span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span @click="editpict(i)">编辑</span>
                        <img
                          src="../../img/板块图标 - 副本_slices/编辑.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span @click="delBtn(i.id)">删除</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/复制.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span
                          @click="
                            seeFn5(i.id);
                            dia5 = true;
                          "
                          >预览</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="img">
                  <img
                    src="../../img/板块图标 - 副本_slices/图片 (3) 拷贝 2.png"
                    alt=""
                  />
                </div>
                <b>图文资源展示</b>
                <div class="btn" v-show="i.status != 1">
                  <router-link
                    :to="{
                      path: '/main/addscene/pictureresource',
                      query: {
                        csid: csid,
                        dataid: i.id,
                        filetype: i.file_type,
                      },
                    }"
                    >添加资源</router-link
                  >
                  <div class="del" @click="delBtn(i.id)">删除</div>
                </div>
                <div class="btn" v-show="i.status == 1">{{ i.file_name }}</div>
              </li>

              <!-- 06 问卷 -->
              <li v-for="(i, v) in questionlist" :key="v + '5'">
                <div class="elp" v-if="i.status">
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    style="height: 50/96in; display: flex"
                  >
                    <span
                      class="el-dropdown-link"
                      style="display: flex; align-items: right"
                      ><img
                        class="more"
                        src="../../img/上课/设 置.png"
                        style="width: 0.14rem"
                        alt=""
                    /></span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span @click="editques(i)">编辑</span>
                        <img
                          src="../../img/板块图标 - 副本_slices/编辑.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span @click="delBtn(i.id)">删除</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/复制.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span
                          @click="
                            seeFn6(i.id);
                            dia6 = true;
                          "
                          >预览</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="img">
                  <img
                    src="../../img/板块图标 - 副本_slices/？ 拷贝 2.png"
                    alt=""
                  />
                </div>
                <b>问卷资源展示</b>
                <div class="btn" v-show="i.status != 1">
                  <router-link
                    :to="{
                      path: '/main/addscene/topicresource',
                      query: {
                        csid: csid,
                        dataid: i.id,
                        filetype: i.file_type,
                      },
                    }"
                    >添加资源</router-link
                  >
                  <div class="del" @click="delBtn(i.id)">删除</div>
                </div>
                <div class="btn" v-show="i.status == 1">{{ i.file_name }}</div>
              </li>

              <!-- 07 模型 -->
              <li v-for="(i, v) in modellist" :key="v + '6'">
                <div class="elp" v-if="i.status">
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    style="height: 50/96in; display: flex"
                  >
                    <span
                      class="el-dropdown-link"
                      style="display: flex; align-items: right"
                      ><img
                        class="more"
                        src="../../img/上课/设 置.png"
                        style="width: 0.14rem"
                        alt=""
                    /></span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span @click="edit(i)">编辑</span>
                        <img
                          src="../../img/板块图标 - 副本_slices/编辑.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span @click="delBtn(i.id)">删除</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/复制.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span
                          @click="
                            seeFn7(i.id);
                            dia7 = true;
                          "
                          >预览</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="img">
                  <img
                    src="../../img/板块图标 - 副本_slices/三维分析 拷贝 2.png"
                    alt=""
                  />
                </div>
                <b>模型资源展示</b>
                <div class="btn" v-show="i.status != 1">
                  <router-link
                    :to="{
                      path: '/main/addscene/pptresource',
                      query: {
                        csid: csid,
                        dataid: i.id,
                        filetype: i.file_type,
                      },
                    }"
                    >添加资源</router-link
                  >
                  <div class="del" @click="delBtn(i.id)">删除</div>
                </div>
                <div class="btn" v-show="i.status == 1">{{ i.file_name }}</div>
              </li>

              <!-- 08 试题 -->
              <li v-for="(i, v) in topiclist" :key="v + '7'">
                <div class="elp" v-if="i.status">
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    style="height: 50/96in; display: flex"
                  >
                    <span
                      class="el-dropdown-link"
                      style="display: flex; align-items: right"
                      ><img
                        class="more"
                        src="../../img/上课/设 置.png"
                        style="width: 0.14rem"
                        alt=""
                    /></span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span @click="editques(i)">编辑</span>
                        <img
                          src="../../img/板块图标 - 副本_slices/编辑.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span @click="delBtn(i.id)">删除</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/复制.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span
                          @click="
                            seeFn8(i.id);
                            dia8 = true;
                          "
                          >预览</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="img">
                  <img
                    src="../../img/板块图标 - 副本_slices/椭圆 2 拷贝 2.png"
                    alt=""
                  />
                </div>
                <b>试题资源展示</b>
                <div class="btn" v-show="i.status != 1">
                  <router-link
                    :to="{
                      path: '/main/addscene/topicresource',
                      query: {
                        csid: csid,
                        dataid: i.id,
                        filetype: i.file_type,
                      },
                    }"
                    >添加资源</router-link
                  >
                  <div class="del" @click="delBtn(i.id)">删除</div>
                </div>
                <div class="btn" v-show="i.status == 1">{{ i.file_name }}</div>
              </li>

              <!-- 09 实景 -->
              <li v-for="(i, v) in reallist" :key="v + '8'">
                <div class="elp" v-if="i.status">
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    style="height: 50/96in; display: flex"
                  >
                    <span
                      class="el-dropdown-link"
                      style="display: flex; align-items: right"
                      ><img
                        class="more"
                        src="../../img/上课/设 置.png"
                        style="width: 0.14rem"
                        alt=""
                    /></span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span @click="editreal(i)">编辑</span>
                        <img
                          src="../../img/板块图标 - 副本_slices/编辑.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span @click="delBtn(i.id)">删除</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/复制.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                      <el-dropdown-item
                        ><span
                          @click="
                            seeFn9(i.id);
                            dia9 = true;
                          "
                          >预览</span
                        ><img
                          src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                          alt=""
                          style="width: 0.1rem; margin-left: 0.1rem"
                      /></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="img">
                  <img
                    src="../../img/板块图标 - 副本_slices/360OnlinVote (1) 拷贝 2.png"
                    alt=""
                  />
                </div>
                <b>实景资源展示</b>
                <div class="btn" v-show="i.status != 1">
                  <router-link
                    :to="{
                      path: '/main/addscene/pptresource',
                      query: {
                        csid: csid,
                        dataid: i.id,
                        filetype: i.file_type,
                      },
                    }"
                    >添加资源</router-link
                  >
                  <div class="del" @click="delBtn(i.id)">删除</div>
                </div>
                <div class="btn" v-show="i.status == 1">{{ i.file_name }}</div>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
    <el-dialog title="PPT资源预览" :visible.sync="dia1" top="3vh">
      <div class="box">
        <iframe
          id="iframe1"
          width="1424"
          height="754"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          allowtransparency="yes"
          :src="ppt"
        ></iframe>
        <!-- https://view.officeapps.live.com/op/view.aspx?src=http://ke.yunlearning.com/c.pptx -->
      </div>
    </el-dialog>
    <el-dialog title="文档资源预览" :visible.sync="dia2">
      <div class="box">
        <iframe
          id="iframe1"
          width="100%"
          height="100%"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          allowtransparency="yes"
          :src="word"
        ></iframe>
      </div>
    </el-dialog>
    <el-dialog title="视频资源预览" :visible.sync="dia3" @close="videoclose">
      <div class="box">
        <video-player
          class="vjs-custom-skin"
          :options="playerOptions"
        ></video-player>
      </div>
    </el-dialog>
    <el-dialog
      title="音频资源预览"
      :visible.sync="dia4"
      class="dia4"
      @close="musicclose"
    >
      <div class="box">
        <div class="main">
          <span>{{ musicname }}</span>
          <div class="pic">
            <img src="../../img/上课/音线.jpg" alt="" />
            <img src="../../img/上课/音线.jpg" alt="" />
          </div>
          <div class="roll">
            <div class="img">
              <img src="../../img/上课/黑胶.png" alt="" />
            </div>
            <div class="btnimg" @click="control">
              <img
                v-if="musicbtn == 0"
                class="stop"
                src="../../img/上课/暂停.png"
                alt=""
              />
              <img v-else class="play" src="../../img/上课/播放.png" alt="" />
            </div>
          </div>
          <div class="music">
            <audio
              id="audio"
              controls
              loop
              @click="control"
              controlsList="nodownload"
              :src="music"
            ></audio>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="图文资源预览" :visible.sync="dia5" class="dia5">
      <div class="box">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(img, index) in banners"
              :key="index"
            >
              <img :src="img" alt />
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <!-- <div class="swiper-pagination"></div> -->
          <div class="swiper-button-prev"></div>
          <div class="title" v-html="imgname"></div>
        </div>
        <div class="smallbox">
          <div class="smallimg">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(img, index) in banners"
                :key="index + '1'"
                @click="to(index + 1)"
              >
                <img :src="img" alt />
              </div>
            </div>
          </div>

          <div class="swiper-button-next s1"></div>
          <!-- <div class="swiper-pagination1"></div> -->
          <div class="swiper-button-prev s2"></div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="问卷资源预览" :visible.sync="dia6" class="dia6">
      <div class="box">
        <ul class="topiclists">
          <li v-for="(item, index) in ques" :key="index">
            <div class="top">
              <div class="title">
                <b>{{ index + 1 }}</b
                >. <span v-show="item.type == 0">选择题</span
                ><span v-show="item.type != 0">判断题</span>

                <div class="titletopic" v-html="item.question"></div>
              </div>
              <ul>
                <li>
                  <div>A</div>
                  <span v-html="item.a"> </span>
                </li>
                <li>
                  <div>B</div>
                  <span v-html="item.b"> </span>
                </li>
                <li>
                  <div>C</div>
                  <span v-html="item.c"> </span>
                </li>
                <li>
                  <div>D</div>
                  <span v-html="item.d"></span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </el-dialog>
    <el-dialog title="试题资源预览" :visible.sync="dia8" class="dia8">
      <div class="box">
        <ul class="topiclists">
          <li v-for="(item, index) in topic" :key="index">
            <div class="top">
              <div class="title">
                <b>{{ index + 1 }}</b
                >. <span v-show="item.type == 0">选择题</span
                ><span v-show="item.type != 0">判断题</span>

                <div class="titletopic" v-html="item.question"></div>
              </div>
              <ul>
                <li>
                  <div>A</div>
                  <span v-html="item.a"> </span>
                </li>
                <li>
                  <div>B</div>
                  <span v-html="item.b"> </span>
                </li>
                <li>
                  <div>C</div>
                  <span v-html="item.c"> </span>
                </li>
                <li>
                  <div>D</div>
                  <span v-html="item.d"></span>
                </li>
              </ul>
            </div>
            <div class="bottom">
              <div class="title">
                <div class="txt">
                  正确答案：<span v-html="item.answer"></span>
                </div>
              </div>
              <div class="info">解析：<span v-html="item.analysis"></span></div>
            </div>
          </li>
        </ul>
      </div>
    </el-dialog>
    <el-dialog title="实景资源预览" :visible.sync="dia9" class="dia9">
      <div class="box">
        <Three1 v-bind:real="real" />
        <!-- <iframe src="" frameborder="0"></iframe> -->
      </div>
    </el-dialog>
    <el-dialog title="模型资源预览" :visible.sync="dia7" class="dia7">
      <div class="box">
        <iframe
          id="iframe1"
          width="1424"
          height="754"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          allowtransparency="yes"
          :src="model"
        ></iframe>
      </div>
    </el-dialog>
    <el-dialog
      title="添加授课过程"
      :visible.sync="addCourses"
      class="addCourse"
    >
      <div class="box">
        <el-input
          v-model="bigcourse"
          placeholder="请输入授课过程名称"
        ></el-input>
        <div class="addcourseBtn">
          <el-button @click="addCourses = false">取消</el-button>
          <el-button type="primary" @click="addCourse()">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="添加授课过程小节"
      :visible.sync="smallCatalog"
      class="addCourse"
    >
      <div class="box">
        <el-input
          v-model="smallcourse"
          placeholder="请输入授课过程名称"
        ></el-input>
        <div class="addcourseBtn">
          <el-button @click="smallCatalog = false">取消</el-button>
          <el-button type="primary" @click="smallAdd()">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="授课过程名称修改"
      :visible.sync="bigEdito"
      class="addCourse"
    >
      <div class="box">
        <el-input v-model="bigEdits" :placeholder="oldBigEdit"></el-input>
        <div class="addcourseBtn">
          <el-button @click="bigEdito = false">取消</el-button>
          <el-button type="primary" @click="bigEdit()">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="授课小节名称修改"
      :visible.sync="smallEdito"
      class="addCourse"
    >
      <div class="box">
        <el-input v-model="smallEdits" :placeholder="oldSmallEdit"></el-input>
        <div class="addcourseBtn">
          <el-button @click="smallEdito = false">取消</el-button>
          <el-button type="primary" @click="smallEdit()">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as util from "@/unit/network";
import $ from "jquery";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import Swiper from "swiper";
import Three1 from "../../components/attend/Three1.vue";
// directive
var swiper;
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {
      num: "00",
      list: "",
      cid: "", //
      csid: "",
      pptlist: "", // ppt
      wenlist: "", // 文档
      videolist: "", // 视频
      mp3list: "", // 音频
      picturelist: "", // 图文
      questionlist: "", // 问卷
      modellist: "", // 模型
      topiclist: "", // 试题
      reallist: "", // 实景
      dia1: false,
      ppt: "",
      dia2: false,
      word: "",
      dia3: false,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度

        autoplay: false, //如果true,浏览器准备好时开始回放。

        controls: true, //控制条

        preload: "auto", //视频预加载

        muted: false, //默认情况下将会消除任何音频。

        loop: false, //导致视频一结束就重新开始。

        language: "zh-CN",

        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）

        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。

        sources: [
          {
            type: "video/mp4",

            src: require("../../assets/薛之谦-演员(标清).mp4"),

            // src: "",

            //src: 'src/views/sjsc/sjsc01_carInfoManage/2.mp4'//你所放置的视频的地址，最好是放在服务器上
          },
        ],

        //poster: "http://39.106.117.192:8080/static/indexImg.png", //你的封面地址（覆盖在视频上面的图片）

        width: document.documentElement.clientWidth,

        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
      dia4: false,
      musicname: "",
      music: "",
      musicimgurl: "",
      musicbtn: 1,
      dia5: false,
      banners: [],
      imgname: "",
      dia6: false,
      ques: [],
      dia8: false,
      topic: [],
      dia7: false,
      model: "",
      dia9: false,
      real: "",
      addCourses: false, // 添加授课过程
      bigcourse: "", // 授课过程名称
      smallCatalog: false,
      smallcourse: "",
      smallId: "",
      // 左侧边栏编辑
      bigEdito: false,
      bigEdits: "",
      oldBigEdit: "",
      smallEdito: false,
      smallEdits: "",
      oldSmallEdit: "",
    };
  },
  computed: {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      util.post("/catalog", { csid: this.$route.query.csid }, (res) => {
        // console.log(res.data);
        this.list = res.data;
        this.csid = this.$route.query.csid;
        this.getcatalogFile(res.data[0].catalog[0].id);
      });
    },
    // 点击插入ppt
    getcatalogFile(id) {
      util.post("/catalogFile", { catalog_id: id }, (res) => {
        this.cid = id;
        if (res.code == 1) {
          this.pptlist = res.data.filter((item) => item.file_type == "1");
          this.wenlist = res.data.filter((item) => item.file_type == "2");
          this.videolist = res.data.filter((item) => item.file_type == "3");
          this.mp3list = res.data.filter((item) => item.file_type == "4");
          this.picturelist = res.data.filter((item) => item.file_type == "5");
          this.questionlist = res.data.filter((item) => item.file_type == "6");
          this.modellist = res.data.filter((item) => item.file_type == "7");
          this.topiclist = res.data.filter((item) => item.file_type == "8");
          this.reallist = res.data.filter((item) => item.file_type == "9");
        }
      });
    },
    addSome(i) {
      util.post(
        "/catalogFileAdd",
        { file_type: i, catalog_id: this.cid },
        (res) => {
          if (res.code == 1) {
            this.getcatalogFile(this.cid);
          }
        }
      );
    },
    // 删除li
    delBtn(id) {
      util.post("/catalogFileDel", { id: id }, (res) => {
        console.log(res);
        util.post("/catalogFile", { catalog_id: this.cid }, (res) => {
          // this.cid = id;
          if (res.code == 1) {
            this.pptlist = res.data.filter((item) => item.file_type == "1");
            this.wenlist = res.data.filter((item) => item.file_type == "2");
            this.videolist = res.data.filter((item) => item.file_type == "3");
            this.mp3list = res.data.filter((item) => item.file_type == "4");
            this.picturelist = res.data.filter((item) => item.file_type == "5");
            this.questionlist = res.data.filter(
              (item) => item.file_type == "6"
            );
            this.modellist = res.data.filter((item) => item.file_type == "7");
            this.topiclist = res.data.filter((item) => item.file_type == "8");
            this.reallist = res.data.filter((item) => item.file_type == "9");
          }
        });
      });
    },

    // 编辑li
    edit(i) {
      this.$router.push({
        path: "/main/addscene/pptresource",
        query: {
          csid: this.csid,
          dataid: i.id,
          filetype: i.file_type,
        },
      });
    },
    // 编辑图文
    editpict(i) {
      this.$router.push({
        path: "/main/addscene/pictureresource",
        query: {
          csid: this.csid,
          dataid: i.id,
          filetype: i.file_type,
        },
      });
    },
    // 编辑问卷
    editques(i) {
      this.$router.push({
        path: "/main/addscene/topicresource",
        query: {
          csid: this.csid,
          dataid: i.id,
          filetype: i.file_type,
          edit: 1,
        },
      });
    },
    editreal(i) {
      this.$router.push({
        path: "/main/addscene/pptresource/pptlocal",
        query: {
          csid: this.csid,
          dataid: i.id,
          filetype: i.file_type,
        },
      });
    },

    // ppt预览
    seeFn(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        this.ppt =
          "https://view.officeapps.live.com/op/view.aspx?src=" +
          "http://zy.yunlearning.com" +
          res.data.file_path;
        // this.imgurl = res.data.cover;
        console.log(res);
      });
    },
    // 文档预览
    seeFn2(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        this.word =
          "https://view.officeapps.live.com/op/view.aspx?src=" +
          "http://zy.yunlearning.com" +
          res.data.file_path;
      });
    },
    // 视频预览
    seeFn3(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        console.log(res);
        this.playerOptions.sources[0].src = res.data.file_path;
      });
    },
    // videoclose(action, instance, done) {
    //   console.log(action, instance, done);
    // },
    // 音频预览
    seeFn4(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        console.log(res);
        this.music = res.data.file_path;
        this.musicname = res.data.file_name;
        this.musicinfo();
      });
    },
    musicinfo() {
      var audio = document.getElementById("audio");

      // console.log(audio.duration);
      if (audio.paused) {
        $(".pic img").css({
          webkitAnimationPlayState: "paused",
        });
        $(".roll .img img").css({
          webkitAnimationPlayState: "paused",
        });
      } else {
        $(".pic img").css({
          webkitAnimationPlayState: "running",
        });
        $(".roll .img img").css({
          webkitAnimationPlayState: "running",
        });
      }
    },
    control() {
      var audio = document.getElementById("audio");
      if (audio !== null) {
        if (audio.paused) {
          audio.play(); // 播放
          this.musicbtn = 0;
          $(".pic img").css({
            webkitAnimationPlayState: "running",
          });
          $(".roll .img img").css({
            webkitAnimationPlayState: "running",
          });
        } else {
          audio.pause(); // 暂停
          this.musicbtn = 1;

          $(".pic img").css({
            webkitAnimationPlayState: "paused",
          });
          $(".roll .img img").css({
            webkitAnimationPlayState: "paused",
          });
        }
      }
    },
    // 图文预览
    seeFn5(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        console.log(res);
        this.banners = res.data.file_path.split(",");
        this.imgname = res.data.content;

        this.$nextTick(() => {
          swiper = new Swiper(".swiper-container", {
            // autoplay: {
            //   delay: 1000, //1秒切换一次
            // },

            loop: true,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            // pagination: {
            //   el: ".swiper-pagination",
            //   clickable: true,
            // },
          });
        });
        this.$nextTick(() => {
          new Swiper(".smallimg", {
            loop: false,
            slidesPerView: 5,
            navigation: {
              nextEl: ".s1",
              prevEl: ".s2",
            },
          });
        });
      });
    },
    to(i) {
      console.log(i);
      swiper.slideTo(i, 500, false);
    },
    // 问卷预览
    seeFn6(id) {
      util.post("/questionnaireList", { catalog_file_id: id }, (res) => {
        console.log(res);
        this.ques = res.data;
      });
    },
    // 试题
    seeFn8(id) {
      util.post("/questionList", { catalog_file_id: id }, (res) => {
        console.log(res);
        this.topic = res.data;
      });
    },
    // 模型
    seeFn7(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        console.log(res);

        let obj = JSON.parse(res.data.file_path);
        // http://192.168.1.210:8093
        this.model =
          `/threejs/a.html?names=` + obj.file_name + "&" + "path=" + obj.path;
      });
    },
    seeFn9(id) {
      util.post("/catalogFileInfo", { id: id }, (res) => {
        console.log(res);
        this.real = res.data.file_path;
      });
    },

    over(i) {
      $(".right .top li")
        .eq(i - 1)
        .children("img")
        .eq(0)
        .css({
          display: "none",
        });
      $(".right .top li")
        .eq(i - 1)
        .children("img")
        .eq(1)
        .css({
          display: "block",
        });
    },
    leave(i) {
      $(".right .top li")
        .eq(i - 1)
        .children("img")
        .eq(1)
        .css({
          display: "none",
        });
      $(".right .top li")
        .eq(i - 1)
        .children("img")
        .eq(0)
        .css({
          display: "block",
        });
    },
    delBig(id) {
      util.post(
        "/catalogDel",
        {
          id: id,
        },
        (res) => {
          if (res.code == 1) {
            this.getList();
          }
        }
      );
    },
    leftDel(id) {
      util.post("/catalogDel", { id: id }, (res) => {
        if (res.code == 1) {
          this.getList();
        }
      });
    },
    // 添加授课过程
    addCourse() {
      if (this.bigcourse == "") {
        return;
      } else {
        util.post(
          "/catalogAdd",
          {
            catalog_name: this.bigcourse,
            csid: this.$route.query.csid,
            pid: 0,
            order: 0,
          },
          (res) => {
            console.log(res);
            if (res.code == 1) {
              this.addCourses = false;
              this.getList();
            }
          }
        );
      }
    },
    getid(id) {
      this.smallId = id;
    },
    smallAdd() {
      if (this.smallCatalog == "") {
        return;
      } else {
        util.post(
          "/catalogAdd",
          {
            catalog_name: this.smallcourse,
            csid: this.$route.query.csid,
            pid: this.smallId,
            order: 0,
          },
          (res) => {
            console.log(res);
            if (res.code == 1) {
              this.smallCatalog = false;
              this.getList();
            }
          }
        );
      }
    },
    getEdit(id, names) {
      this.bigeditid = id;
      this.oldBigEdit = names;
    },
    bigEdit() {
      util.post(
        "/catalogEdit",
        {
          catalog_name: this.bigEdits,
          id: this.bigeditid,
          order: 0,
        },
        (res) => {
          if (res.code == 1) {
            this.bigEdito = false;
            this.getList();
          }
        }
      );
    },
    leftbj(id, names) {
      this.smalleditid = id;
      this.oldSmallEdit = names;
      console.log(id, names);
    },
    smallEdit() {
      util.post(
        "/catalogEdit",
        {
          catalog_name: this.smallEdits,
          id: this.smalleditid,
          order: 0,
        },
        (res) => {
          if (res.code == 1) {
            this.smallEdito = false;
            this.getList();
          }
        }
      );
    },
    videoclose() {
      this.playerOptions.sources[0].src = "";
    },
    musicclose() {
      this.music = "";
    },
  },
  components: {
    Three1,
  },
};
</script>
<style lang="less" scoped>
#addscene {
  display: flex;
  height: 100%;
  .left {
    width: 2.38rem /* 238/100 */;
    height: 100%;
    border: 1px solid #cdcdcd;
    border-top: none;
    border-bottom: none;
    .title {
      height: 0.44rem /* 44/100 */;
      border-bottom: 1px solid #cdcdcd;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0.14rem /* 14/100 */;
      background-color: #e5e7ea;
      span {
        display: inline-block;
        width: 16/96in;
        height: 16/96in;
        background-color: #1c5eff;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 16/96in;
      }
      b {
        font-size: 0.14rem /* 14/100 */;
        font-weight: bold;
        margin-left: 0.08rem /* 8/100 */;
        color: #131313;
      }
    }
    .classList {
      width: 2.38rem;
      font-size: 0.14rem /* 14/100 */;
      font-weight: 500;
      color: #000000;
      line-height: 0.63rem /* 63/100 */;
      .head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 0.17rem /* 17/100 */;
        cursor: pointer;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          color: #131313;
          font-size: 18px;
          font-weight: bold;
        }
        .line {
          width: 18px;
          margin: 0 7px 0 8px;
        }
      }
      .smallList {
        .active {
          font-weight: bold;
        }
        li:hover {
          font-weight: bold;
        }
        li {
          display: flex;
          align-items: center;
          padding: 0 15px 0 33px;
          line-height: 40px;
          cursor: pointer;
          .left_img {
            width: 16px;
            margin-right: 6px;
          }
          span {
            width: 100%;
            font-size: 14px;
            color: #131313;
          }
          .right_img {
            // width: 36/96in;
            display: flex;
            img:first-child {
              margin-right: 9px;
            }
          }
        }
      }
    }
  }
  .right {
    width: 100%;
    main {
      height: 100%;
      .top {
        height: 44px;
        border-bottom: 1/96in solid #cdcdcd;
        display: flex;
        padding-left: 53px;
        li {
          display: flex;
          align-items: center;
          font-size: 12/96in;
          cursor: pointer;
          margin-right: 33px;
          img {
            width: 24px;
          }
          img:nth-child(2) {
            display: none;
          }

          b {
            margin-left: 16px;
          }
        }
      }
      .main {
        padding: 21px 39px 0;
        height: calc(100% - 104px);
        // overflow: hidden;
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #131313;
        }
        .mains::-webkit-scrollbar {
          width: 0;
        }
        .mains {
          height: 100%;
          overflow: auto;
          .box {
            width: 413px;
            text-align: center;
            position: absolute;
            top: 363px;
            // transform: translateY(-50%);
            left: 905px;
            img {
              width: 100%;
            }
            span {
              font-size: 16px;
              font-weight: 400;
              color: #a9a9a9;
              margin-top: 2px;
            }
          }
          .img {
            width: 76px;
            height: 96px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
            }
          }
          // addppt
          .pptList {
            margin-top: 40px;
            margin-left: 4px;

            li {
              float: left;
              width: 210px;
              height: 208px;
              background: #ffffff;
              box-shadow: 0px 4px 7px 1px rgba(131, 147, 245, 0.14);
              text-align: center;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              font-size: 12/96in;
              margin-right: 36/96in;
              margin-bottom: 34/96in;
              position: relative;
              .elp {
                position: absolute;
                top: 14px;
                right: 14px;
                cursor: pointer;
              }
              b {
                margin-top: 12px;
                margin-bottom: 20px;
                padding: 0 3px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .btn {
                display: flex;

                a,
                .del {
                  border: 1px solid #a8a8a8;
                  border-radius: 3px;
                  color: #7a7a7a;
                  // min-width: 61px;
                  // width: 60px;
                  // overflow: hidden;
                  // white-space: nowrap;
                  // text-overflow: ellipsis;
                }
                a {
                  margin-right: 26px;
                  padding: 4px 11px;
                }
                .del {
                  padding: 4px 23px;
                  cursor: pointer;
                }
                @media (max-width: 1475px) {
                  a {
                    padding: 4px 4px;
                  }
                  .left {
                    padding: 4px 16px;
                  }
                }
                @media (max-width: 1297px) {
                  a {
                    padding: 4px 0;
                  }
                  .left {
                    padding: 0px 12px;
                  }
                }
              }
            }
            @media (max-width: 1216px) {
              .right {
                width: 1050px !important;
              }
              li {
                width: 121/96in;
                height: 120/96in;
                .img {
                  img {
                    width: 50/96in !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/deep/.el-dropdown-menu__item {
  line-height: 20px !important;
}

/deep/.el-dialog__header {
  text-align: center;
  color: #2c2c2c;
  font-weight: bold;
  padding-top: 35/96in;
}

/deep/.el-dialog {
  width: 1600px !important;
  height: 900px;
  // margin-top: 7.5vh !important;
  margin: 7.5vh auto !important;
}
/deep/.el-dialog__body::-webkit-scrollbar {
  width: 0;
}
/deep/.el-dialog__body {
  padding: 20px 30px 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  .box {
    width: 1424px;
    height: 754px;
  }
}
.dia4 {
  .box {
    position: relative;
  }
  .main {
    width: 91%;
    height: 744px;
    // background: url();
    position: absolute;
    // top: 50%;
    left: 50%;
    transform: translate(-50%, 9%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    overflow: hidden;
    background: #2d3b5c;
    span {
      display: inline-block;
      width: 100%;
      height: 130px;
      background-color: rgba(0, 0, 0, 0.8);
      text-align: left;
      color: #fff;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
      padding: 31px 0 0 61px;
      font-size: 16px;
      font-weight: bold;
    }
    .pic {
      width: 1164px;
      height: 257px;
      background-color: #1c7cff;
      border-radius: 20px;
      display: flex;
      overflow: hidden;
      position: relative;
      img {
        height: 100%;
        animation: myimg 11s linear infinite;
      }
      @keyframes myimg {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-1211.56px);
        }
      }
    }
    .roll {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -55%);
      > .img {
        > img {
          width: 410px;
          animation: rollimg 6s linear infinite;
        }
      }
      .btnimg {
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      @keyframes rollimg {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .pause {
        animation-play-state: paused;
      }
    }
    audio {
      z-index: 9;
      width: 100%;
      border-radius: 0 !important;
    }
    .music {
      width: 100%;
      height: 130px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    }
    .img1 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      z-index: -1;
    }
  }
}
.dia5 {
  .box {
    .swiper-container {
      width: 1024px !important;
      height: 576px;
      overflow: hidden;
      .swiper-slide {
        img {
          width: 100%;
        }
      }
      .title {
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 169px;
        padding: 66px 13px 0 15px;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(0, 0, 0, 1)
        );
        line-height: 25px;
        font-size: 14px;
        color: #fff;
        z-index: 1;
      }
    }
    .smallbox {
      display: flex;
      justify-content: center;
      position: relative;
      .smallimg {
        margin-top: 37px;
        width: 1024px !important;
        height: 112px;
        overflow: hidden;
        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 168px;
          }
        }
      }
      .s1,
      .s2 {
        top: 66%;
      }
      .s1 {
        right: 93px;
      }
      .s2 {
        left: 93px;
      }
    }
  }
}
.dia6,
.dia8 {
  .topiclists {
    margin-right: 40px;
    > li {
      background-color: #fff;
      padding: 48px 131px 56px 74px;

      margin-bottom: 5px;
      .top {
        .title {
          font-weight: 400;
          color: #2c2c2c;
          b {
            font-weight: 400;
          }
          .titletopic {
            display: inline-block;

            /deep/img {
              width: 100px !important;
              vertical-align: top;
              margin: 0 2px;
            }
          }

          span {
            display: inline-block;
            width: 56px;
            height: 22px;
            line-height: 22px;
            background: #b3c9ff;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 400;
            color: #2769e1;
            text-align: center;
          }
        }
        ul {
          // margin-bottom: 61px;
          li {
            display: flex;
            align-items: center;
            margin-top: 23px;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 15px;
              width: 28px;
              height: 28px;
              border: 1px solid #cdcdcd;
              border-radius: 50%;
            }
            /deep/p {
              display: flex;
              align-items: center;
            }
            /deep/img {
              width: 100px !important;
              vertical-align: top;
              margin: 0 2px;
            }
          }
        }
      }
      .bottom {
        //   margin-top: 34px;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .txt {
            margin-top: 23px;
            color: #000;
            font-size: 14px;
            cursor: pointer;
          }
        }
        .info {
          font-size: 16px;
          color: #2c2c2c;
          line-height: 36px;
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
}
.dia9 {
  .box {
    overflow: hidden;
  }
}
.addCourse {
  /deep/.el-dialog {
    width: 394/96in !important;
    height: 414/96in !important;
    // margin: 0 auto !important;
    // margin-top: 22.5vh !important;
  }
  /deep/.el-dialog__body {
    height: calc(100% - 0.69rem) !important;
    .box {
      width: 100%;
      height: 100%;
      input {
        margin-top: 20px;
      }
      .addcourseBtn {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 100px;
      }
    }
  }
}
.el-dropdown-link {
  .more {
    width: 14/96in !important;
  }
}
</style>