import { render, staticRenderFns } from "./addScene.vue?vue&type=template&id=deae3312&scoped=true&"
import script from "./addScene.vue?vue&type=script&lang=js&"
export * from "./addScene.vue?vue&type=script&lang=js&"
import style0 from "./addScene.vue?vue&type=style&index=0&id=deae3312&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "deae3312",
  null
  
)

export default component.exports